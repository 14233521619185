var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding1",staticStyle:{"background":"#f4f5fa","min-height":"80vh"}},[_c('v-card',{staticClass:"elevation-0 pb-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Usuarios "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.createDialog=true}}},[_vm._v("Agregar Nuevo")])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"expand-icon":"","headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',{style:('color:'+item.color+';')},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.last))])]}},{key:"item.job_position",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job_position)+" / "+_vm._s(item.sub_job_position)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status(item.status))+" ")]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px!important"}},_vm._l((item.permissions),function(permission,i){return _c('v-chip',{key:i,staticClass:"my-0 mx-1 px-1",attrs:{"x-small":"","color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.permissionText(permission))+" ")])}),1)]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen procedencias registradas. ")])],2),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editItem',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('createItem',{on:{"closeDialogItem":_vm.closeDialogItem}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }