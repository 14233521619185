var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding1",staticStyle:{"background":"#f4f5fa","min-height":"80vh"}},[(_vm.info==true)?_c('v-card',{staticClass:"elevation-0 pb-6 px-7",staticStyle:{"background":"transparent"}},[_c('v-card-text',{staticClass:"py-3 px-6",staticStyle:{"background":"#1976d1","color":"white","border-radius":"5px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white","small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" Lorem ipsum dolor sit amet consectetur adipiscing elit massa imperdiet, mauris tortor platea fringilla sodales ac magna sociosqu, natoque non rutrum nec auctor vestibulum congue eget. "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"position":"absolute!important","top":"7px","right":"30px"},attrs:{"color":"white","small":""},on:{"click":function($event){_vm.info=false}}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"elevation-0 pb-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Productos | Servicios "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.createDialog=true}}},[_vm._v("Agregar Nuevo")])],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.product_type=='Variable')?_c('td',{staticStyle:{"padding":"0px!important"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 pa-5",staticStyle:{"background":"#f7f7f7"},attrs:{"headers":_vm.headers2,"items":_vm.variations(item.id),"dense":""},scopedSlots:_vm._u([{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_vm._l((item.categories),function(categori,index){return [_c('span',{key:index},[_vm._v(_vm._s(_vm.category(categori))+", ")])]})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.cost))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}},{key:"item.provider_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.provider(item.provider_id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen procedencias registradas. ")])],2)],1):_vm._e()]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return [_vm._l((item.categories),function(categori,index){return [_c('span',{key:index},[_vm._v(_vm._s(_vm.category(categori))+", ")])]})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.cost))+" ")]}},{key:"item.is_published",fn:function(ref){
var item = ref.item;
return [(item.product_type!='Variable')?_c('div',[(item.is_published==true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-record")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-record")])],1):_vm._e()]}},{key:"item.unit_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.unit(item.unit_id))+" ")]}},{key:"item.provider_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.provider(item.provider_id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" No existen procedencias registradas. ")])],2),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editItem',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('createItem',{on:{"closeDialogItem":_vm.closeDialogItem}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }